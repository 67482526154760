.dialog {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: center;

	> .curtain {
		background-color: gray;
		opacity: 0.5;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	}

	> div:first-child {
		background-color: white;
		border-radius: 4px;
		max-height: 90%;
		max-width: 90%;
		display: flex;
		flex-direction: column;

		> * {
			padding: 4px 6px;
			margin: 4px;
		}

		&.full {
			height: 90%;
			width: 90%;
		}
		&.wide {
			width: 90%;
		}
	}

	header,
	footer {
		position: relative;
		border: none;
		background-color: transparent;
		width: auto;
		line-height: inherit;
	}

	section {
		overflow: auto;

		.searchResults {
			max-height: 300px;
			overflow: auto;
		}

		&.contacts {
			display: flex;
			flex-wrap: wrap;

			> div {
				width: 80px;
				text-align: center;
				font-size: 80%;
				margin: 2px 0;
				padding: 12px 2px 4px;
				cursor: pointer;

				&:hover {
					background-color: $hoverColor;
				}

				> img,
				> .photo {
					display: block;
					margin: 0 auto 4px;
					width: 64px;
					height: 64px;
					font-size: 48px;
					font-family: cursive;
					line-height: 64px;
					background-color: #d7d7d7;
					color: #ffffff;
					border-radius: 4px;
				}

				&.field {
					width: 100%;
					padding: 0;

					&:hover {
						background-color: unset;
					}
				}
			}
		}
	}

	footer {
		button {
			margin: 4px 8px;
			padding: 0 16px;
		}
	}

	&.dropArea {
		align-items: flex-start;

		.curtain {
			border: 2px white dotted;
		}

		> div:first-child {
			margin-top: 48px;
			padding: 24px;
			border-radius: 24px;
			background-color: adjust-color($actionColor, $alpha: -0.2);
			color: white;
			font-weight: bold;
		}
	}
}

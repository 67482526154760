@import "./constants.scss";

.content {
	position: relative;

	> div {
		line-height: 24px;
		overflow-wrap: break-word;

		&.image {
			padding: 0 4px;
		}
	}

	> p {
		margin: 4px 8px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.block {
		margin: 4px 8px;
		display: block;

		&.inline {
			display: inline-block;
		}

		button {
			margin-right: 4px;
		}

		.note {
			font-size: 75%;
			display: block;
		}
	}

	.field {
		margin: 4px 8px;
		&.error {
			color: #e00000;
		}

		&.boolean {
			span {
				margin: 8px;
				display: inline-block;

				i {
					vertical-align: middle;
					cursor: pointer;
					user-select: none;
				}
			}
		}

		> a {
			display: block;
			font-size: 80%;
			text-align: right;
			color: inherit;
		}

		input[type="file"] {
			padding-left: 0;
			border: none;
		}

		.image {
			.icon {
				width: 64px;
				height: 64px;
				line-height: 60px;
				font-size: 42px;
				margin: auto;
				text-align: center;
				white-space: nowrap;
				border: 4px solid #f0f0f0;
				display: block;
			}
		}
	}

	.field-group {
		display: flex;

		.field {
			flex: auto;
		}

		button {
			margin: 4px 4px -4px;
			align-self: flex-end;
		}
	}

	label {
		font-size: 0.8em;
		color: #a7a7a7;
		margin-right: 4px;
		display: block;

		&.inline {
			display: inline;
		}
	}

	input,
	textarea,
	select {
		display: block;
		border: solid 1px #e7e7e7;
		border-radius: 4px;
		padding: 4px 6px;
		width: 100%;
		box-sizing: border-box;
		font-family: inherit;
	}

	textarea {
		min-height: 3em;
	}

	img {
		min-width: 32px;
		min-height: 32px;
		max-width: 100%;
		max-height: 600px;
		box-sizing: border-box;
		margin: 0 auto;
		display: block;
	}

	.actions {
		padding: 4px;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		button,
		.button {
			margin: 4px;
			padding: 4px 16px;
			font-size: 24px;
			min-height: 36px;
			color: black;

			&:hover {
				background-color: $actionColor;
				color: white;
			}

			&[disabled]:hover {
				background-color: #e7e7e7;
				color: black;
			}
		}

		input {
			margin: 4px;
		}
	}

	iframe {
		display: block;
		width: 100%;
		height: 480px;
		border: 0;
		margin: 8px 0;
	}

	.searchResults {
		> * {
			margin: 4px;
		}
	}

	.icons {
		display: flex;
		justify-content: space-around;
		align-items: center;
		flex-wrap: wrap;

		.icon {
			display: flex;
			width: 64px;
			height: 64px;
			font-size: 48px;
			margin: 4px;
			cursor: pointer;
			align-items: center;
			justify-content: center;

			&:hover {
				border: solid 16px $hoverColor;
				width: 32px;
				height: 32px;
				font-size: 24px;
			}
		}

		img {
			max-width: 64px;
		}
	}

	pre {
		font-size: 80%;
		overflow: auto;
		line-height: 1.2em;
		margin: 0;
	}

	.map {
		width: 100%;
		height: 400px;
		margin: 12px auto;
	}

	.template {
		button {
			width: 24px;
			height: 24px;
			min-width: 16px;
			min-height: 16px;
			margin-left: 4px;

			.icon {
				font-size: inherit;
			}
		}
	}

	.items {
		margin-bottom: 0;
	}
}

.missing {
	margin: 24px;
	padding: 2px;
	background-color: $messageColor;
	text-align: center;
	border-radius: 8px;

	div {
		margin: 24px 8px 4px;
	}

	button {
		margin: 8px;
		padding: 16px 24px;
	}
}

.boxes {
	i.state {
		width: auto;
		height: auto;
		font-size: 24px;
		color: gray;
		align-self: center;
	}

	.complete {
		i.state {
			color: black;
		}
	}

	.passive {
		cursor: default;
		i.state {
			color: lightgray;
		}
	}
}

.mark {
	font-size: 10px;
	margin: 1px;
	padding: 0 2px;
	min-width: 12px;
	height: 14px;
	line-height: 14px;
	display: inline-block;
	text-align: center;
	border-radius: 4px;
	background-color: lightgray;
}

.marks {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

@import "./constants.scss";

.view {
	header,
	footer,
	.place-header {
		position: fixed;
		width: $appWidth;
		z-index: 1;
		box-sizing: border-box;
	}

	header {
		background-color: $headerColor;
		display: flex;
		align-items: center;
		line-height: $headerHeight;
		padding: 2px;
		font-size: 18px;
		top: 0;
		border-top: solid $appBorderWidth $bodyColor;

		> button {
			padding: 2px;
			color: #a7a7a7;
			background-color: transparent;

			i {
				margin: 0;
				padding: 0;
			}

			&:hover {
				color: #777777;
			}

			&.active {
				color: black;

				&:hover {
					color: white;
				}
			}

			&[disabled] {
				color: #a7a7a7;
				background-color: transparent;

				&:hover {
					color: #a7a7a7;
					background-color: transparent;
				}
			}

			&.pinned {
				margin-right: -4px;
			}
		}

		.icon {
			width: 32px;
			height: 32px;
			line-height: 32px;
			font-size: 22px;
		}

		> .logo.icon {
			margin: 8px;
		}

		> div {
			overflow: hidden;
			flex-grow: 1;
			line-height: normal;

			> div {
				display: flex;
				align-items: center;

				> .icon {
					margin-right: 4px;
					flex: none;

					& > .icon.box.status {
						margin: 2px -32px;
						font-size: 14px;
					}
				}

				> .logo.icon {
					margin: 8px;
				}

				> span {
					flex: auto;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;

					> span {
						color: darkcyan;
					}

					> i {
						color: #a7a7a7;
						margin-left: 8px;
					}
				}

				&.complete > span {
					overflow: auto;
					white-space: normal;
				}
			}
		}
	}

	section {
		padding: $headerHeight + 4 0 80px;

		&.conflicts {
			pre {
				font-size: 80%;
				padding: 0 8px;
				overflow: auto;
			}
		}
	}

	&.content {
		header {
			min-height: $headerHeight + 12 + $appBorderWidth;
		}
		section {
			padding-top: $headerHeight + 12;
		}
		header > div > div:first-child {
			font-size: 75%;
			// line-height: 28px;
			margin: 2px 0;
			cursor: pointer;
			white-space: nowrap;

			.icon {
				width: 24px;
				margin-left: 4px;
				height: 24px;
				line-height: 24px;
				font-size: 12px;
			}

			a {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	&.place {
		.place-header {
			border-top: solid $appBorderWidth $bodyColor;
			top: 0;
			line-height: 24px;
			padding: 4px 8px;
			color: black;
			background-color: lighten($actionColor, 30%);
			button {
				min-height: auto;
				min-width: auto;
				padding: 0;
				float: right;
			}
		}
		header {
			margin-top: 32 + $appBorderWidth;
			border: none;
			button.place {
				margin: 0 2px;
			}
			> div {
				opacity: 0.5;
			}
		}
		&.placed header > div {
			opacity: 1;
		}
		section {
			padding-top: $headerHeight + 4 + 32;
		}
		.links {
			.entry {
				opacity: 0.5;
				cursor: auto;
			}
			.placed .entry {
				opacity: 1;
				cursor: pointer;
			}
		}
		button.place {
			min-height: auto;
			min-width: auto;
			padding: 2px 4px;
			color: black;
			background-color: lighten($actionColor, 30%);

			&:hover {
				color: white;
				background-color: $actionColor;
			}

			&:disabled {
				opacity: 0.5;
				&:hover {
					color: black;
					background-color: lighten($actionColor, 30%);
				}
			}

			.icon {
				width: 24px;
				height: 24px;
				line-height: 24px;
				font-size: 24px;
				margin: 4px;
			}
		}
	}

	&.content.place {
		header {
			min-height: $headerHeight + 12;
		}
		section {
			padding-top: $headerHeight + 12 + 32;
		}
	}

	footer {
		padding: 6px 8px 6px 8px;
		background-color: $footerColor;
		bottom: 0;
		border-bottom: solid $appBorderWidth $bodyColor;
		font-size: 70%;

		.buttons {
			position: absolute;
			right: 12px;
			bottom: 10px;

			button {
				font-size: 20px;
				margin-left: 8px;
				border-radius: 8px;
				padding: 8px 12px;
				box-shadow: 1px 3px 4px #000000a7;

				i.material-icons {
					vertical-align: bottom;
				}

				&.plus {
					font-size: 24px;
					border-radius: 24px;
					padding: 12px 17px;
				}
			}
		}

		span {
			cursor: pointer;
		}
	}

	& > footer {
		padding-right: 64px;
	}
}

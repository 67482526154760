@media only screen and (max-width: 480px) {
	body {
		padding: 0;
	}

	#app {
		width: 100%;
	}

	.view {
		header,
		footer,
		.place-header {
			width: 100%;
		}

		header {
			font-size: 20px;
			padding-top: 4px;
			border-top: none;

			i.material-icons {
				font-size: 28px;
			}

			.menu {
				padding: 0 18px;
			}
		}

		section {
			padding: $headerHeight + 6 0;
		}

		footer {
			border-bottom: none;
		}

		&.content {
			header {
				min-height: $headerHeight + 12;
			}
		}

		&.place {
			.place-header {
				border-top: none;
			}
			header {
				margin-top: 32px;
			}
		}
	}
}

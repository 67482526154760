@import '../constants.scss';

.calendar {
	> .header {
		font-size: 120%;
		line-height: 32px;
		padding: 8px 16px;

		button {
			float: right;
			min-width: 32px;
			min-height: 32px;
			background-color: transparent;

			&:hover {
				color: black;
				background-color: $actionColorLight;
			}
		}

		.selector {
			display: inline-block;

			> div:first-child {
				cursor: pointer;

				&:hover {
					color: #a7a7a7;
				}
			}

			> div:nth-child(2) {
				position: absolute;
				width: 92%;
				font-size: initial;
				background-color: $actionColor;
				color: $actionTxtColor;
				padding: 8px 0;
				border-radius: 4px;
				text-align: center;

				> div {
					display: flex;
					justify-content: center;
					padding: 4px;

					button {
						color: $actionTxtColor;
						float: unset;
						margin: 0 4px;
					}
				}

				> span {
					display: inline-block;
					padding: 1%;
					margin: 1%;
					width: 29%;
					border-radius: 4px;
					cursor: pointer;

					&:hover {
						background-color: $actionColorLight;
					}

					&.selected {
						opacity: 0.5;
					}
				}
			}
		}
	}

	> .days {
		display: grid;
		grid-template-columns: repeat(7, calc(100% / 7));

		> div {
			text-align: center;
			display: block;
			margin: 2px;
			background-color: #e7e7e7;
			border-radius: 2px;
			min-height: 42px;
			line-height: 1;
			font-size: 80%;
			cursor: pointer;

			&:hover {
				background-color: $hoverColor;
			}

			> h6 {
				font-size: 80%;
				margin: 0;
				text-align: right;
				padding: 2px;
			}

			> div {
				font-size: 67%;
				margin: 4px 2px;
				text-align: left;
				line-height: normal;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				text-overflow: ellipsis;
				overflow: hidden;

				p {
					margin: 0;
				}
			}
		}

		.weekday {
			min-height: auto;
			padding: 4px;
			background-color: transparent;
			cursor: default;

			&:hover {
				background-color: transparent;
			}
		}

		.day6,
		.day7 {
			background-color: #c0c0c0;
		}

		.today {
			background-color: #a0d0a0;
		}
	}
}

@import '../constants.scss';

.comment {
	h6 {
		font-size: 80%;
		color: #a7a7a7;
		margin: 4px 4px 0;

		& > span {
			float: right;
		}
	}

	p {
		margin: 0 4px;
	}
}

.items .entry > div.comment,
.links .link .entry > div.comment {
	display: block;
	margin: 0 6px;
	> h6 {
		font-size: 80%;
	}

	.icon {
		display: none;
	}
	> div {
		h6 {
			color: black;
			font-size: 90%;
		}
		.url {
			font-size: 75%;
			color: darkgray;
		}
	}
}

.message {
	position: fixed;
	right: 0;
	bottom: 42px;
	max-width: 60%;
	max-height: 90%;
	overflow: auto;
	z-index: 3;

	> div {
		margin: 8px 0;
		padding: 16px;
		min-height: 20px;
		background-color: orange;
		border-radius: 6px 0 0 6px;

		&.error {
			background-color: red;
			color: white;
		}

		&.more {
			cursor: pointer;
			margin-left: 50%;
		}

		.action {
			float: right;
			margin: -8px -10px 4px 12px;
			color: #d0d0d0;
			cursor: pointer;

			&:hover {
				color: #ffffff;
			}
		}

		pre {
			font-size: 80%;
			overflow: auto;
		}

		span {
			float: left;
			margin: -14px 0 0 -12px;
			font-size: 67%;
			color: #ffd0d0;
		}
	}
}

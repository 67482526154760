$actionTxtColor: #ffffff;
$actionColor: #1c81ca;
$actionColorLight: lighten($actionColor, 20%);
$hoverColor: lighten($actionColor, 45%);
$menuMarginRight: 2px;
$appWidth: 480px;
$appBorderWidth: 8px;
$appColor: white;
$bodyColor: gray;
$headerColor: #d2dbdf;
$headerHeight: 48px;
$footerColor: #c4cfd4f0;
$messageColor: orange;

@import "./constants.scss";

.tags {
	padding: 2px 4px 1px;
	font-size: 80%;
	position: relative;
	border-bottom: solid 1px $appColor;

	.tag {
		border-radius: 4px;
		padding: 2px 4px;
		margin: 2px;
		display: inline-block;
		min-height: 1em;
	}

	&.expanded {
		border-bottom-color: #d7d7d7;
	}

	> .options {
		position: absolute;
		right: 8px;
		background-color: $appColor;
		z-index: 1;
		border: solid 1px #d7d7d7;
		border-top: solid 3px $appColor;
		border-radius: 0 0 4px 4px;
		max-height: 80vh;
		overflow: auto;

		> div {
			padding: 4px 8px;
			cursor: pointer;

			&:hover {
				background-color: $hoverColor;
			}

			.icon {
				margin-right: 4px;
			}
		}
	}
}

@import './constants.scss';
@import './layout.scss';
@import './dialog.scss';
@import './properties.scss';
@import './content.scss';
@import './links.scss';
@import './tags.scss';
@import './log.scss';
@import './utils.scss';
@import './boxes.scss';
@import './items.scss';
@import './layout/calendar.scss';
@import './layout/comment.scss';
@import './layout/url.scss';
@import './timeline.scss';

// general, global rules
h1 {
	color: #c7c7c7;
	font-size: 120%;
	margin: 4px 0 8px;
}

h5 {
	color: #a7a7a7;
	padding: 4px 8px;
	margin: 8px 0;
	font-weight: normal;
}

h6 {
	margin: 6px 0;
	font-weight: normal;
}

.error {
	color: red;
}

a {
	color: #0057a7;
	text-decoration: none;

	&:hover {
		color: #0077b7;
	}
}

// layout

html,
body {
	height: 100vh;
}

body {
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	background-color: $bodyColor;
	padding: $appBorderWidth;
	margin: 0;
	box-sizing: border-box;
	// overflow-y: scroll;
}

::placeholder {
	opacity: 0.5;
}

#app {
	width: $appWidth;
	margin: auto;
	background-color: $appColor;
	min-height: 100%;
}

body.touch {
	button:hover {
		background-color: transparent;
		color: black;
	}

	.items > * {
		&:hover {
			background-color: transparent;
		}

		&.selected > .link {
			&:hover {
				background-color: darken($hoverColor, 10%);
			}
		}
	}

	.links > div > .link:hover {
		background-color: transparent;
	}
}

.edit {
	> footer {
		padding-right: 120px;
	}

	.myLocation {
		position: absolute;
		right: 0;
		margin: 12px 6px;
		box-shadow: 1px 3px 4px #000000a7;
		z-index: 500;
	}
}

.icon {
	font-style: normal;
	font-size: inherit;
	flex: none;
	text-align: center;
	border-radius: 4px;
	overflow: hidden;
	vertical-align: middle;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	img {
		max-width: 100%;
		max-height: 100%;
	}

	& > .icon {
		color: inherit;
		line-height: inherit;
		margin: 0;
		width: inherit;
		height: inherit;
	}

	& .mark {
		position: absolute;
		margin: 20px -20px 0 0;
	}

	&.text {
		font-family: cursive;
	}

	&.passive {
		color: gray;
		opacity: 0.5;
	}
}

.fileUpload {
	position: relative;

	input {
		position: absolute;
		margin: 0;
		padding: 0;
		opacity: 0;
		width: 0.1px;
		height: 0.1px;
		z-index: -1;
	}
}

div.img {
	max-width: 100%;
	align-self: center;

	img {
		max-width: 100%;
		align-self: center;
		display: block;
	}

	&.icon {
		flex: none;
		margin: 2px;
	}
}

div.img::before {
	content: '';
	width: 1px;
	margin-left: -1px;
	float: left;
	height: 0;
	padding-top: var(--h);
}

.result {
	margin-left: 24px;

	> .items {
		max-height: 360px;
		overflow: auto;
		margin: 0;

		> div {
			border-top-color: #e7e7e7;
			padding: 0;

			.entry > i {
				padding: 2px;
			}
		}
	}

	> div:last-child {
		text-align: right;
		font-size: 80%;
		color: #777777;
		padding: 2px 4px 4px;
	}
}

button,
.button {
	min-width: 48px;
	min-height: 48px;
	line-height: 1;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	background-color: lighten($actionColor, 30%);
	box-sizing: border-box;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;

	&:hover {
		color: $actionTxtColor;
		background-color: $actionColor;
	}

	&:focus,
	&.focus {
		outline: none;
		box-shadow: 0px 0px 2px blue;
	}

	&[disabled] {
		cursor: default;
		color: #777777;
		background-color: #e7e7e7;

		&:hover {
			background-color: #e7e7e7;
		}
	}

	.icon {
		font-size: 24px;
	}
}

.menu {
	line-height: 24px;
	font-size: 20px;
	position: relative;
	display: inline-block;

	&:before {
		content: '⋮';
	}

	> div {
		display: none;
		position: absolute;
		top: 4px;
		right: 2px;
		padding: 8px;
		text-align: left;
		color: $actionTxtColor;
		background-color: $actionColor;
		border: 2px solid $actionColor;
		border-radius: 4px 0 4px 4px;
		white-space: nowrap;

		> div,
		> span {
			padding: 8px 12px;
			margin: 4px;
			border-radius: 4px;
			white-space: nowrap;
			display: flex;
			align-items: center;

			&:hover {
				background-color: $actionColorLight;
			}

			.icon {
				margin-right: 4px;
			}
		}

		> span {
			display: inline-block;
		}
	}

	&.open {
		color: $actionTxtColor;
		background-color: $actionColor;
		border-radius: 4px 4px 0 0;

		> div {
			display: block;
		}
	}
}

.search {
	display: flex;

	input {
		line-height: 36px;
		padding: 0 8px;
		font-size: 16px;
		flex: auto;
		margin: 2px;
	}
}

.results {
	> section {
		&:nth-child(even) {
			background-color: #f7f7f7;
		}

		&:hover {
			background-color: $hoverColor;
		}

		> header {
			display: flex;
			margin: 4px;
			line-height: 40px;

			> i {
				padding-right: 4px;
			}

			> span {
				flex: auto;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}

			> button {
				margin-left: 4px;
				flex: none;
			}
		}

		> div {
			font-size: 80%;
			padding: 0 2px 12px 8px;
		}
	}

	.material-icons {
		line-height: inherit;
	}
}

.add {
	a,
	.fileUpload {
		cursor: pointer;
		display: inline-block;
		margin: 8px;
		padding: 6px;
		border-radius: 8px;
		color: black;

		&:hover {
			background-color: $hoverColor;
		}
	}

	.button {
		min-width: unset;
		min-height: unset;
		background-color: unset;

		&:hover {
			color: unset;
		}
	}

	i.material-icons {
		font-size: 32px;
	}
}

.linkTo {
	div {
		cursor: pointer;
		padding: 4px 8px;
	}

	&.disabled div {
		cursor: default;
	}
}

div.cropperjs {
	padding: 0;
	margin: 4px;
}

.field div.cropperjs {
	width: auto;
	min-height: 64px;
}

@import './mobile.scss';

.install {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	text-align: center;

	div {
		background-color: $messageColor;
		opacity: 0.9;
		height: 100%;
		width: 100%;
		position: absolute;
		z-index: -1;
	}

	button {
		margin: 8px;
		padding: 16px 24px;
	}
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.loading {
	line-height: 32px;
	padding: 4px 10px;
	color: #d7d7d7;
	animation: fadein 3s;
}

img.loading {
	display: block;
	margin: 0 auto;
	padding: 0;
}

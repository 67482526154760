@import '../constants.scss';

.url {
	&.link {
		.url {
			font-size: 75%;
			color: darkgray;
			padding: 2px 0;
		}

		.external.icon {
			width: 42px;
			height: auto;
			line-height: normal;
			font-size: 120%;
			margin: 0;
			padding: 0 4px;
			color: darkgray;

			&:hover {
				color: #0057a7;
			}
		}
	}
}

@keyframes spinning {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.spin {
	animation: spinning 2s linear infinite;
}

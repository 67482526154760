@import './constants.scss';

.links {
	> div {
		border-top: none;
		padding: 0;

		&.selected > .link {
			background-color: darken($hoverColor, 10%);

			&:hover {
				background-color: darken($hoverColor, 5%);

				.action .icon {
					background-color: darken($hoverColor, 5%);
				}
			}

			.action .icon {
				background-color: darken($hoverColor, 10%);
			}
		}

		> .name {
			color: #a7a7a7;
			font-size: 67%;
			padding: 2px 2px 0px;
			border-left: 2px solid #e7e7e7;
			margin-left: 12px;
		}

		&.named {
			> .link,
			> div.expand {
				margin-left: 12px;
				border-left: 2px solid #e7e7e7;
				padding-left: 16px;
			}
			&:hover {
				background-color: inherit;
			}
		}
	}

	.link {
		display: flex;
		align-items: center;
		padding: 4px 4px;

		&:hover {
			background-color: $hoverColor;

			.action .icon {
				background-color: $hoverColor;
			}
		}

		&.small {
			.entry {
				min-height: 24px;

				> .icon:first-child {
					width: 24px;
					height: 24px;
					font-size: 16px;
					line-height: normal;
					margin: 6px 0;
				}

				> div {
					display: block;

					> h6 {
						font-size: 90%;
						float: left;
						margin: 3px 4px 3px 0;
						font-weight: bold;

						&::after {
							content: ': ';
						}
					}

					> p {
						-webkit-line-clamp: 1;
						margin: 3px 3px 3px 0;
					}
				}
			}
		}

		&.compact {
			.entry {
				> .icon:first-child {
					width: 32px;
					height: 32px;
					font-size: 18px;
					line-height: 28px;
					margin: 4px 0;

					&.small {
						width: 24px;
						height: 24px;
						margin: 8px 4px;
					}
				}

				> div {
					h6 {
						font-size: 95%;
					}

					> p {
						-webkit-line-clamp: 2;
					}
				}
			}
		}

		&.normal {
			.entry {
				> .icon:first-child {
					width: 42px;
					height: 42px;
					font-size: 24px;
					line-height: 44px;
					margin: 6px 3px;

					&.small {
						width: 32px;
						height: 32px;
						margin: 12px 8px;
					}
				}

				> div {
					> h6 {
						font-size: 110%;
					}

					> p {
						-webkit-line-clamp: 3;
					}
				}
			}
		}

		&.extended {
			.entry {
				> .icon:first-child {
					margin: 4px 0;

					&.small {
						width: 48px;
						height: 48px;
						margin: 12px 8px;
					}
				}

				> div {
					> p {
						-webkit-line-clamp: 6;
					}
				}
			}
		}

		&.full {
			.entry {
				> .icon:first-child {
					margin: 4px 0;

					&.small {
						width: 48px;
						height: 48px;
						margin: 12px 8px;
					}
				}

				> div {
					> p {
						display: block;
					}
				}
			}
		}
	}
}

@import './constants.scss';

.items {
	margin-bottom: 40px;
	position: relative;
	line-height: initial;

	> * {
		border-top: 1px solid #f0f0f0;
		padding: 1px 2px;

		&:hover {
			background-color: $hoverColor;
		}

		button.expand {
			height: 24px;
			width: 24px;
			padding: 2px;
			margin: 2px;
			color: #a7a7a7;
			background-color: transparent;
			flex: 0 0 auto;
			align-self: center;

			i.material-icons {
				font-size: 24px;
				margin: 0;
				width: auto;
				height: auto;
				line-height: inherit;
			}

			&:hover {
				color: #ffffff;
				background-color: $actionColor;
			}
		}

		div.action {
			padding: 1px 4px;
			color: #a7a7a7;
			position: absolute;
			right: 0;

			&.check {
				cursor: pointer;
			}

			&.reorder {
				cursor: move;
			}

			.icon {
				background-color: white;
				font-size: 36px;
				width: 36px;
				height: 36px;
				line-height: normal;
			}
		}

		span.expand {
			width: 24px;
			height: 24px;
			color: transparent;
		}

		div.expand {
			text-align: right;
			display: block;
		}

		.entry {
			// line-height: 24px;
			color: inherit;
			text-decoration: none;
			cursor: pointer;
			display: flex;
			align-items: stretch;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			flex: auto;
			min-height: 32px;

			&.disabled {
				cursor: default;
			}

			> div {
				flex: auto;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin: 6px 4px;
				font-size: 80%;

				> h6 {
					font-size: 100%;
					font-weight: normal;
					margin: 0;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				> p {
					font-size: 90%;
					margin: 0;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: normal;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}
		}

		.missing {
			color: #ff0000;
			padding: 8px 36px 8px 12px;
			white-space: normal;
		}

		.container {
			font-size: 66%;
			margin-top: -2px;
		}
		&.content-link .container {
			display: none;
		}
	}

	&.selecting {
		> * {
			div.reorder {
				cursor: pointer;
			}
		}
	}

	.icon {
		width: 48px;
		height: 48px;
		margin: 4px;
		line-height: 48px;
		font-size: 40px;

		&.box.status {
			font-size: 24px;
		}
	}

	img {
		max-width: 100%;
		max-height: 600px;
	}

	&.sorting {
		> *:hover {
			background-color: transparent;
		}
		> * > .link:hover {
			background-color: transparent;
		}
	}

	> .sortable-chosen {
		background-color: $hoverColor;
	}

	> * .entry > .marks {
		flex: 0 0 auto;
	}

	> .section {
		color: #a7a7a7;
		font-size: 80%;
		padding: 4px;
		cursor: pointer;

		.icon {
			width: 14px;
			height: 14px;
			line-height: 12px;
			display: inline-block;
			font-size: 20px;
			margin: 0;
		}
	}

	&.shortcuts {
		margin: 8px;
		display: flex;
		justify-content: space-around;

		.icon {
			width: 48px;
			height: 48px;
		}

		> * {
			border: none;
		}
	}

	&.results {
		> * {
			padding: 2px 12px;

			&:first-child {
				border-top: none;
			}

			&.section {
				padding: 6px;

				.icon {
					width: 14px;
					height: 14px;
				}
			}

			.icon {
				width: 42px;
				height: 42px;
			}
		}
	}

	.deleted {
		text-decoration: line-through;
	}
}
